<template>
  <div>
    <br>
    <fieldset style="width:97%">
      <legend>搜索信息</legend>
      <el-row :gutter="20">
        <el-col :span="3">
          <el-input v-model="exName" placeholder="人员姓名"></el-input>
        </el-col>
        <el-col :span="3">
          <el-cascader
              :show-all-levels="false"
              style="width: 100%"
              multiple="false"
              placeholder="人员所属渠道"
              ref="source"
              :options="sourceArray"
              :props="{checkStrictly: true,expandTrigger: 'hover'}"
              v-model="exSource"
              @change="cascaderClsoe"
              :clearable="true"
          ></el-cascader>
        </el-col>
        <el-col :span="3">
          <el-button type="primary" @click="search">搜索</el-button>
        </el-col>
      </el-row>
    </fieldset>
    <el-col style="margin-top: 10px;margin-left: 10px">
      <el-button type="primary" @click="dialog.addEmpState = true" style="margin-bottom: 10px">添加员工</el-button>
      <el-table
          :row-style="{height:36+'px'}"
          :cell-style="{padding:5+'px'}"
          :data="table.externalEmpList"
          border
          height="650"
          max-height="650"
          :default-sort = "{prop: 'id', order: 'ascending'}"
          style="width: 100%">
        <el-table-column prop="exName" label="人员名称" align="center"></el-table-column>
        <el-table-column prop="exSourceName" label="人员对应渠道" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="exCompany" label="人员所属公司" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="exCity" label="人员所属城市" align="center" ></el-table-column>
        <el-table-column prop="exIsValid" label="在职状态" align="center" ></el-table-column>
        <el-table-column width="350" align="center" fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button-group>
              <el-button type="success" size="mini" @click="openEdit(scope.row)">编辑</el-button>
              <el-button type="warning" size="mini" @click="deleteEmp(scope.row)">删除</el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </el-col>
    <el-col style="margin-top: 5px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="this.table.limit"
          layout="prev, pager, next,total, jumper, sizes"
          :total="table.total">
      </el-pagination>
    </el-col>
    <externalEmpAdd v-if="dialog.addEmpState" :state="dialog.addEmpState" @close="close"/>
    <externalEmpEdit v-if="dialog.editEmpState" :externalEmp="dialog.externalEmp" :state="dialog.editEmpState" @close="close"/>
  </div>
</template>

<script>
import externalEmpAdd from "@/pages/emp/emp/external-emp-add";
import externalEmpEdit from  "@/pages/emp/emp/external-emp-edit";
export default {
  name: "external-emp-manage",
  data(){
    return{
      exName:"",
      exSource:[],
      exSourceName:[],
      tenantCrop:localStorage.getItem("tenantCrop"),
      selectionOptions:[],
      table:{
        externalEmpList:[],
        page:1,
        limit:100,
        total:0,
      },
      sourceArray:[],
      dialog:{
        addEmpState:false,
        editEmpState:false,
        externalEmp:{},
      }

    }
  },
  components:{
    externalEmpAdd,
    externalEmpEdit,
  },
  created() {
    this.queryExternalEmpList();
    this.querySourceArray();
  },
  methods:{
    search(){
      this.table.page=1;
      this.queryExternalEmpList();
    },
    //查询用户列表
    queryExternalEmpList:function (){
      let exLength = this.exSource.length - 1;
      this.$axios({
        method:"GET",
        url:"/externalEmp/loadExternalEmp",
        params:{
          page: this.table.page,
          limit: this.table.limit,
          exName: this.exName,
          exSource: this.exSource[exLength],
          tenantCrop: this.tenantCrop
        }
      }).then(response=>{
        this.table.total=response.data.data.total;
        this.table.externalEmpList=response.data.data.list;
      })
    },
    //打开编辑界面
    openEdit(data){
      this.dialog.editEmpState = true
      this.dialog.externalEmp = data
    },
    //查询渠道
    querySourceArray:function (){
      this.$selectUtils.querySourceIds(this.$selectUtils.cascader).then(response => {
        this.sourceArray = JSON.parse(JSON.parse(response.data.data))
      })
    },
    //删除客户
    deleteEmp(data){
      console.log(this.source)
      this.$confirm("删除员工", '删除员工:', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$axios({
          method:"delete",
          url:"/externalEmp/deleteExternalEmpById",
          params:{
            id:data.id,
            tenantCrop:this.tenantCrop
          }
        }).then(response=>{
          if (response.data.code === 200) {
            this.$message.success("删除成功!")
            let self=this;
            setTimeout(function (){self.queryExternalEmpList()},1000)
          } else {
            this.$message.error(response.data.msg)
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    //级联选择关闭
    cascaderClsoe() {
      this.$refs.source.dropDownVisible = false;
    },
    //更改每页长度
    handleSizeChange:function (val){
      this.table.limit=val;
      this.queryExternalEmpList();
    },
    handleCurrentChange(val) {
      this.table.page =val
      this.queryExternalEmpList()
    },
    close(){
      this.dialog.addEmpState=false
      this.dialog.editEmpState=false
      this.queryExternalEmpList();
    }
  }
}
</script>

<style scoped>

</style>