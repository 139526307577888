<template>
  <el-dialog
      title="添加员工"
      width="55%"
      :visible.sync="dialogState"
      :modal-append-to-body="false"
      @close="close"
      center>
    <el-form label-width="100px" :model="form" :rules="rules" style="margin-left: 40px" ref="form">
      <el-row>
        <el-col :span="7">
          <el-form-item label="人员姓名" prop="exName">
            <el-input v-model="form.exName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="联系渠道" prop="exSource">
            <el-cascader
                :show-all-levels="false"
                style="width: 100%"
                multiple="false"
                placeholder="人员所属渠道"
                ref="source"
                :options="sourceArray"
                :props="{checkStrictly: true,expandTrigger: 'hover'}"
                v-model="form.exSource"
                @change="cascaderClsoe"
                :clearable="true"
            ></el-cascader>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="7">
          <el-form-item label="所属公司" prop="exCompany">
            <el-input v-model="form.exCompany"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="所在城市" prop="exCity">
            <el-input v-model="form.exCity"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="7">
          <el-form-item label="在职状态" prop="exIsValid">
            <el-radio-group v-model="form.exIsValid">
              <el-radio :label="1">在职</el-radio>
              <el-radio :label="0">离职</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addEmpCheck()">提 交</el-button>
        <el-button @click="close">取 消</el-button>
      </span>
  </el-dialog>
</template>

<script>
export default {
  name: "external-emp-add",
  data() {
    return {
      sourceArray: [],
      form: {
        //公司名
        exName: "",
        exSource: [],
        exCompany: "",
        exCity: "",
        exIsValid: "",
        tenantCrop: localStorage.getItem("tenantCrop"),
      },
      rules: {
        exName: [
          {required: true, message: '请输入员工名', trigger: 'blur'},
        ],
        exSource: [
          {required: true, message: '请选择联系渠道', trigger: 'change'},
        ],
        exCompany: [
          {required: true, message: '请输入所属公司', trigger: 'change'},
        ],
        exCity: [
          {required: true, message: '请输入所在城市', trigger: 'blur'},
        ],
        exIsValid: [
          {required: true, message: '请选择在职状态', trigger: 'change'},
        ],
      },
      dialogState: this.state,
    }
  },
  props: {
    state: {
      type: Boolean,
      required: true,
      default: false,
    }
  },
  created() {
    this.querySourceArray();
  },
  methods: {
    //检查订单校验
    addEmpCheck: function () {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.addEmp();
        }
      })
    },
    //添加员工
    addEmp: function () {
      let value = "是否添加该员工?"
      this.$confirm(value, '添加员工:', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        let sourceLength = this.form.exSource.length - 1;
        this.$axios({
          method: "POST",
          url: "/externalEmp/addExternalEmp",
          params: {
            exName: this.form.exName,
            exSource: this.form.exSource[sourceLength],
            exCompany: this.form.exCompany,
            exCity: this.form.exCity,
            exIsValid: this.form.exIsValid,
            tenantCrop: localStorage.getItem("tenantCrop"),
          }
        }).then(response => {
          if (response.data.code === 200) {
            this.$message.success("添加成功!")
            let self = this;
            setTimeout(function () {
              self.close()
            }, 1500)
          } else {
            this.$message.error(response.data.msg)
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    //查询渠道
    querySourceArray:function (){
      this.$selectUtils.querySourceIds(this.$selectUtils.cascader).then(response => {
        this.sourceArray = JSON.parse(JSON.parse(response.data.data))
      })
    },
    //级联选择关闭
    cascaderClsoe() {
      this.$refs.source.dropDownVisible = false;
    },
    close: function () {
      this.$emit("close", false);
    }
  }
}
</script>

<style scoped>

</style>